import { t } from '@core/i18n';
import { ConfigCoreConstants } from '@shared/models/config';
import { Range } from '@shared/types/common/range';

import { getMetricString } from './text';

export const getCurrencySymbol = (constants: ConfigCoreConstants, iso?: string): string => {
  const currencyIso = iso ?? constants.localisation.currencyDefaultIso;

  return (currencyIso && constants.localisation.currencyDefinitions[currencyIso]?.symbol) || '';
};

export const currencyString = (currencySymbol: string, value: any, curencyPosition: NumberBoolean = 0) => {
  return curencyPosition === 0
    ? `${currencySymbol}${numberWithCommas(value)}`
    : `${numberWithCommas(value)}${currencySymbol}`;
};

export const costPerString = (
  currencySymbol: string,
  value: any,
  metric?: Metric | null,
  curencyPosition?: NumberBoolean
) => {
  return t('xvaluex_or_xmetricx', {
    value: currencyString(currencySymbol, value, curencyPosition),
    metric: metric ? getMetricString(metric) : '',
  });
};

export const formatRangeMetric = (range: Partial<Range>, metric?: string) => {
  let result = range.from;

  if (range.to) {
    result += ` - ${range.to}`;
  }

  if (metric) {
    result += ` ${metric}`;
  }

  return result;
};

export const formatCostRange = (currencySymbol: string, from?: string | number, to?: string | number): string => {
  if (from && to) {
    if (from === to) {
      return `${currencyString(currencySymbol, from)}`;
    } else {
      return `${currencyString(currencySymbol, from)} - ${currencyString(currencySymbol, to)}`;
    }
  } else if (from && !to) {
    return t('from_xfromx', { from: `${currencyString(currencySymbol, from)}` });
  } else if (!from && to) {
    return t('up_to_xtox', { to: `${currencyString(currencySymbol, to)}` });
  }

  return '';
};

export const getNullableNumber = (value?: string | number | null): number | null => {
  if (typeof value === 'string') {
    return value ? parseFloat(value) : null;
  } else if (typeof value === 'number') {
    return value;
  }

  return null;
};

export const numberWithCommas = (value?: string | number) => {
  const numberValue = getNullableNumber(value);

  if (numberValue === null) {
    return '';
  }

  if (Number.isInteger(numberValue)) {
    return numberValue.toLocaleString();
  }

  return numberValue.toLocaleString(undefined, {
    minimumFractionDigits: 2,
    maximumFractionDigits: 2,
  });
};

export const numberWithPadding = (value: number) => (value < 10 ? `0${value}` : String(value));

interface ProcessDecimalConfig {
  decimalPlaces?: number;
  roundIfWhole?: boolean;
}

export const processDecimal = (suppliedValue: string | number, config: ProcessDecimalConfig = {}): string => {
  // console.log('processDecimal. config: ', options);

  let value = '';
  if (typeof suppliedValue === 'number') {
    value = suppliedValue.toString();
  } else if (typeof suppliedValue === 'string') {
    value = suppliedValue;
  }

  if (value === '' || typeof config.decimalPlaces !== 'number') {
    // console.log('processDecimal. return original value: ', value);
    return value;
  }

  // Convert to decimal places first, so we can figure out if whole or not
  // WHY: roundIfWhole could have a value of 10.004, round to 10.00, and bypass logic
  let processedValue = parseFloat(value).toFixed(config.decimalPlaces);
  const floatValue = parseFloat(processedValue);

  // Do not apply decimals to zero values
  if (floatValue === 0) {
    return '0';
  }

  // Check if we can round to whole
  if (config.roundIfWhole && config.decimalPlaces && Number.isInteger(floatValue)) {
    processedValue = floatValue.toFixed(0);
    // console.log('processDecimal. number is whole: ', floatValue, 'original: ', value);
  }

  // Otherwise return the value
  // console.log('processDecimal. value: ', processedValue, 'original: ', value);
  return processedValue;
};

export const processDecimalIntoNumber = (suppliedValue: string | number, config: ProcessDecimalConfig = {}): number => {
  const result = processDecimal(suppliedValue, config);

  if (result === '') {
    return 0;
  }

  return (parseFloat(result) * 100) / 100;
};

export const calculateNumberFromPercentage = (
  suppliedPercentage: string | number,
  suppliedNumber: string | number,
  decimalPlaces = 2
): string => {
  let percentage = 0;
  if (typeof suppliedPercentage === 'string' && suppliedPercentage) {
    percentage = parseFloat(suppliedPercentage);
  } else if (typeof suppliedPercentage === 'number') {
    percentage = suppliedPercentage;
  }

  let number = 0;
  if (typeof suppliedNumber === 'string' && suppliedNumber) {
    number = parseFloat(suppliedNumber);
  } else if (typeof suppliedNumber === 'number') {
    number = suppliedNumber;
  }

  return processDecimal((percentage / 100) * number, { decimalPlaces });
};

export const calculatePercentage = (
  suppliedNumerator: string | number,
  suppliedDenominator: string | number,
  decimalPlaces = 2
): string => {
  let numerator = 0;
  if (typeof suppliedNumerator === 'string' && suppliedNumerator) {
    numerator = parseFloat(suppliedNumerator);
  } else if (typeof suppliedNumerator === 'number') {
    numerator = suppliedNumerator;
  }

  let denominator = 0;
  if (typeof suppliedDenominator === 'string' && suppliedDenominator) {
    denominator = parseFloat(suppliedDenominator);
  } else if (typeof suppliedDenominator === 'number') {
    denominator = suppliedDenominator;
  }

  if (denominator === 0) {
    return '0';
  }

  return processDecimal((numerator / denominator) * 100, { decimalPlaces, roundIfWhole: true });
};

export const generateRandomNumberFromInterval = (min = 1, max = 1000000) => {
  return Math.floor(Math.random() * (max - min + 1) + min);
};

export const fromNumberWithCommas = (value: string) => {
  return value.replace(/,/g, '');
};

export const toNumberWithCommas = (value: string) => {
  const sanitizedValue = fromNumberWithCommas(value);

  return Number(sanitizedValue).toLocaleString();
};

export const validateMinMax = (min?: string | number, max?: string | number) => {
  const minNumber = getNullableNumber(min) ?? 0;
  const maxNumber = getNullableNumber(max) ?? 0;
  // console.log('validateMinMax. min: ', minNumber, 'max: ', maxNumber);

  if (minNumber && maxNumber && minNumber > maxNumber) {
    return t('min_should_be_equal_to_or_less_than_max');
  }

  return '';
};

const sqftToSqm = 10.76391041671;
const sqftToAcre = 43560;
const sqftToHectare = 107639;
const sqftToDesk = 85;

export const sqftToValue = (sqFtValue: number, metric: Metric) => {
  switch (metric) {
    case 'sqm':
      return sqFtValue / sqftToSqm;
    case 'acres':
      return sqFtValue / sqftToAcre;
    case 'hectare':
      return sqFtValue / sqftToHectare;
    case 'desks':
      return sqFtValue / sqftToDesk;

    default:
      return sqFtValue;
  }
};

export const valueToSqFt = (value: number, metric?: Metric) => {
  switch (metric) {
    case 'sqm':
      return value * sqftToSqm;
    case 'acres':
      return value * sqftToAcre;
    case 'hectare':
      return value * sqftToHectare;
    case 'desks':
      return value * sqftToDesk;

    default:
      return value;
  }
};
