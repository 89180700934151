export enum LocationType {
  submarkets = 'submarkets',
  teamSubmarkets = 'team_submarkets',
  postcode = 'postcode',
}

export enum WipFeesType {
  country = 'country',
  city = 'city',
  due_date = 'due_date',
  start_date = 'start_date',
}
